import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import QRCodeGenerator from 'qrcode-generator';
import styles from '../styles/pages/UserPayment.module.css';
import { UserContext } from '../components/contexts/UserContext';

const UserPayment = () => {
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [error, setError] = useState('');
  const [paymentData, setPaymentData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [timeLeft, setTimeLeft] = useState(600); // 10 minutos
  const navigate = useNavigate();
  const { logoUrl, forceUpdateUser } = useContext(UserContext) || {};

  useEffect(() => {
    const token = localStorage.getItem('token');
    const sessionToken = localStorage.getItem('sessionToken');
    setIsAuthenticated(!!token && !!sessionToken);

    if (token && sessionToken) {
      axios.get('/api/admin/public/payment-settings', {
        headers: { Authorization: `Bearer ${token}`, 'session-token': sessionToken }
      })
      .then(res => setPaymentAmount(res.data.paymentAmount))
      .catch(err => {
        console.error('Erro ao obter configurações financeiras:', err);
        if (err.response?.status === 401 || err.response?.status === 403) {
          window.location.href = '/pagamento'; 
        } else {
          setError('Erro ao obter configurações financeiras.');
        }
      });
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      const timer = setInterval(() => {
        setTimeLeft(prev => (prev > 0 ? prev - 1 : 0));
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [isAuthenticated]);

  const handlePayment = async () => {
    setLoading(true);
    setError('');
    const token = localStorage.getItem('token');
    const sessionToken = localStorage.getItem('sessionToken');

    try {
      const response = await axios.post('/api/admin/payment', { amount: paymentAmount }, {
        headers: { Authorization: `Bearer ${token}`, 'session-token': sessionToken },
      });

      if (response.data.success) {
        setPaymentData(response.data.paymentData);
        setModalIsOpen(true);
        startPaymentPolling(response.data.paymentData.txid);
      }
    } catch (err) {
      console.error('Erro ao criar pagamento:', err);
      setError('Erro ao criar pagamento. Verifique as configurações.');
    } finally {
      setLoading(false);
    }
  };

  const startPaymentPolling = (txid) => {
    const token = localStorage.getItem('token');
    const sessionToken = localStorage.getItem('sessionToken');

    const pollingInterval = setInterval(async () => {
      try {
        const statusResponse = await axios.get(`/api/admin/payment-status/${txid}`, {
          headers: { Authorization: `Bearer ${token}`, 'session-token': sessionToken }
        });

        if (statusResponse.data.status === 'PAID') {
          clearInterval(pollingInterval);
          setModalIsOpen(false);
          window.location.href = '/dashboard'; 
        }
      } catch (err) {
        console.error('Erro ao verificar status do pagamento:', err);
      }
    }, 3000);

    setTimeout(() => clearInterval(pollingInterval), 600000);
  };

  const generateQRCode = (text) => {
    if (!text) return null;
    const qr = QRCodeGenerator(0, 'H');
    qr.addData(text);
    qr.make();
    return qr.createDataURL(6);
  };

  const copyToClipboard = (text) => {
    if (!text) return;
    navigator.clipboard.writeText(text)
      .then(() => alert('Código PIX copiado com sucesso!'))
      .catch((err) => console.error('Erro ao copiar código PIX:', err));
  };

  return (
    <div className={styles['payment-container']}>
      {isAuthenticated && (
        <div className={styles['timer']}>
          OFERTA EXPIRA EM: {`${Math.floor(timeLeft / 60)}:${(timeLeft % 60).toString().padStart(2, '0')}`}
        </div>
      )}

      {isAuthenticated ? (
        <div className={styles['payment-card']}>
          <div className={styles['logo-container']}>
            <img src={logoUrl} alt="Logo" className={styles['logo']} />
          </div>

          <h2>Bem Vindo!</h2>
          <p className={styles['marketing-text']}>Você Tentou Acessar uma Página Exclusiva para Assinantes!</p>

          {error && <p className={styles['error-message']}>{error}</p>}

          <button onClick={handlePayment} className={styles['payment-button']} disabled={loading}>
            {loading ? 'Processando...' : 'Obter Acesso'}
          </button>

          <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)} contentLabel="Detalhes do Pagamento">
            <div className={styles['modal-header']}><h2>Detalhes do Pagamento</h2></div>
            {paymentData && (
              <div className={styles['payment-details']}>
                <p><strong>Status:</strong> {paymentData.status}</p>
                {paymentData.qrcodePayload && (
                  <div className={styles['qrcode-container']}>
                    <p><strong>Escaneie o QR Code:</strong></p>
                    <img src={generateQRCode(paymentData.qrcodePayload)} alt="QR Code do Pagamento" className={styles['qrcode-image']} />
                  </div>
                )}
                {paymentData.copypasteCode && (
                  <div className={styles['copypaste-container']}>
                    <p><strong>Código PIX Copia e Cola:</strong></p>
                    <div className={styles['copypaste-wrapper']}>
                      <span className={styles['copypaste-text']}>{paymentData.copypasteCode}</span>
                      <button onClick={() => copyToClipboard(paymentData.copypasteCode)} className={styles['copy-button']}>
                        Copiar
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </Modal>
        </div>
      ) : (
        <div className={styles['marketing-card']}>
          <h2>Venha Fazer Parte!</h2>
          <p className={styles['marketing-message']}>Toda grande jornada começa com um primeiro passo.</p>
          <button onClick={() => navigate('/register')} className={styles['register-button']}>Cadastre-se</button>
        </div>
      )}
    </div>
  );
};

export default UserPayment;
