import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import Select from 'react-select';
import Modal from 'react-modal';
import { Button } from 'react-bootstrap';
import styles from '../styles/pages/AdminGames.module.css';
import makeAnimated from 'react-select/animated';

const AdminGames = () => {
  const [games, setGames] = useState([]);
  const [previousGames, setPreviousGames] = useState([]);
  const [showTodayGames, setShowTodayGames] = useState(true);
  const [modalCreateIsOpen, setModalCreateIsOpen] = useState(false);
  const [modalEditIsOpen, setModalEditIsOpen] = useState(false);
  const [newGame, setNewGame] = useState({
    teamA: '',
    teamB: '',
    matchDate: '',
    matchLink: '',
    bets: [],
  });
  const [editGame, setEditGame] = useState(null);
  const [teams, setTeams] = useState([]);
  const [deleteGameId, setDeleteGameId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // Estados locais para placar/tempo
  const [localScores, setLocalScores] = useState({});
  const [localTimers, setLocalTimers] = useState({});
  // referência para intervals de cada jogo (timer rodando)
  const timerIntervals = useRef({});

  const animatedComponents = makeAnimated();

  // Busca jogos do backend
  const fetchGames = useCallback(async () => {
    try {
      const response = await axios.get(`/api/admin/list-games?page=${currentPage}`);
      setGames(response.data.todayGames);
      setPreviousGames(response.data.previousGames);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Erro ao carregar jogos:', error);
    }
  }, [currentPage]);

  // Polling mais suave, ex. a cada 5s
  useEffect(() => {
    fetchGames();
    const interval = setInterval(fetchGames, 5000);
    return () => clearInterval(interval);
  }, [fetchGames]);

  // Sincroniza localScores e localTimers somente se não estiver rodando timer local
  useEffect(() => {
    const updatedScores = { ...localScores };
    const updatedTimers = { ...localTimers };

    games.forEach((game) => {
      const id = game._id;
      const timerIsRunning = !!timerIntervals.current[id];

      if (!timerIsRunning) {
        // Sobrescreve do backend
        updatedScores[id] = {
          placarA: game.placarA || 0,
          placarB: game.placarB || 0,
        };
        if (game.tempoAoVivo) {
          const [m, s] = game.tempoAoVivo.split(':').map(Number);
          updatedTimers[id] = m * 60 + s;
        } else {
          updatedTimers[id] = 0;
        }
      }
    });

    setLocalScores(updatedScores);
    setLocalTimers(updatedTimers);
  }, [games]);

  const fetchTeams = async () => {
    try {
      const response = await axios.get('/api/admin/list-teams');
      const formattedTeams = response.data.teams.map((team) => ({
        value: team._id,
        label: team.name,
        badge: team.badge,
      }));
      setTeams(formattedTeams);
    } catch (error) {
      console.error('Erro ao carregar times:', error);
    }
  };

  useEffect(() => {
    fetchTeams();
  }, []);

  const toggleShowGames = () => {
    setShowTodayGames(!showTodayGames);
  };

  const openDeleteConfirm = (gameId) => {
    setDeleteGameId(gameId);
  };

  const handleDeleteGame = async () => {
    try {
      await axios.delete(`/api/admin/delete-game/${deleteGameId}`);
      setDeleteGameId(null);
      fetchGames();
    } catch (error) {
      console.error('Erro ao excluir jogo:', error);
    }
  };

  const handleStatusChange = async (gameId, status) => {
    try {
      await axios.put(`/api/admin/edit-game/${gameId}`, { status });
      fetchGames();
    } catch (error) {
      console.error('Erro ao mudar status do jogo:', error);
    }
  };

  const handleCreateGame = async () => {
    try {
      const gameToCreate = { ...newGame };
      await axios.post('/api/admin/create-game', gameToCreate);
      setModalCreateIsOpen(false);
      fetchGames();
    } catch (error) {
      console.error('Erro ao criar jogo:', error);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const [date, time] = dateString.split('T');
    const [year, month, day] = date.split('-');
    return `${day}/${month}/${year} ${time?.slice(0, 5)}`;
  };

  const handleEditGame = async () => {
    try {
      const gameToEdit = { ...editGame };
      await axios.put(`/api/admin/edit-game/${editGame._id}`, gameToEdit);
      setModalEditIsOpen(false);
      fetchGames();
    } catch (error) {
      console.error('Erro ao editar jogo:', error);
    }
  };

  const handleInputChange = (e, game, setGame) => {
    const { name, value } = e.target;
    setGame({ ...game, [name]: value });
  };

  const handleTeamChange = (selectedOption, teamKey, game, setGame) => {
    setGame({ ...game, [teamKey]: selectedOption ? selectedOption.value : '' });
  };

  const handleAddBet = (game, setGame) => {
    setGame({
      ...game,
      bets: [...game.bets, { title: '', subtitle: '', odds: '' }],
    });
  };

  const handleBetChange = (index, field, value, game, setGame) => {
    const updatedBets = [...game.bets];
    updatedBets[index][field] = value;
    setGame({ ...game, bets: updatedBets });
  };

  const openEditModal = (game) => {
    const cloned = JSON.parse(JSON.stringify(game));
    setEditGame(cloned);
    setModalEditIsOpen(true);
  };

  const handlePageChange = (direction) => {
    if (direction === 'prev' && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (direction === 'next' && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Formata segundos em mm:ss
  const formatTime = (seconds) => {
    const mm = String(Math.floor(seconds / 60)).padStart(2, '0');
    const ss = String(seconds % 60).padStart(2, '0');
    return `${mm}:${ss}`;
  };

  // Atualiza placar local + backend, evitando fetchGames imediato se timer rodando
  const updateScore = async (gameId, team, delta) => {
    const currentScore = localScores[gameId] || { placarA: 0, placarB: 0 };
    let newA = currentScore.placarA;
    let newB = currentScore.placarB;

    if (team === 'A') newA = Math.max(newA + delta, 0);
    if (team === 'B') newB = Math.max(newB + delta, 0);

    setLocalScores((prev) => ({
      ...prev,
      [gameId]: { placarA: newA, placarB: newB },
    }));

    // Tempo local
    const currentSeconds = localTimers[gameId] || 0;
    // Está rodando timer?
    const isRunning = !!timerIntervals.current[gameId];

    try {
      await axios.put(`/api/admin/update-game-live/${gameId}`, {
        placarA: newA,
        placarB: newB,
        tempoAoVivo: formatTime(currentSeconds),
        isLive: isRunning,
      });

      // Se timer não está rodando, podemos sincronizar
      if (!isRunning) {
        fetchGames();
      }
    } catch (error) {
      console.error('Erro ao atualizar placar:', error);
    }
  };

  // Inicia timer
  const startTimer = (gameId) => {
    if (timerIntervals.current[gameId]) return; // já rodando

    const currentScore = localScores[gameId] || { placarA: 0, placarB: 0 };
    let currentSeconds = localTimers[gameId] || 0;

    axios
      .put(`/api/admin/update-game-live/${gameId}`, {
        placarA: currentScore.placarA,
        placarB: currentScore.placarB,
        tempoAoVivo: formatTime(currentSeconds),
        isLive: true,
      })
      .catch((err) => console.error('Erro ao iniciar tempo ao vivo:', err));

    // Interval local
    const id = setInterval(async () => {
      currentSeconds += 1;
      setLocalTimers((prev) => ({ ...prev, [gameId]: currentSeconds }));

      try {
        await axios.put(`/api/admin/update-game-live/${gameId}`, {
          placarA: currentScore.placarA,
          placarB: currentScore.placarB,
          tempoAoVivo: formatTime(currentSeconds),
          isLive: true,
        });
      } catch (error) {
        console.error('Erro ao atualizar tempo ao vivo:', error);
      }
    }, 1000);

    timerIntervals.current[gameId] = id;
  };

  // Pausa timer => chamamos fetchGames() para sincronizar
  const stopTimer = (gameId) => {
    if (timerIntervals.current[gameId]) {
      clearInterval(timerIntervals.current[gameId]);
      delete timerIntervals.current[gameId];
    }

    const currentScore = localScores[gameId] || { placarA: 0, placarB: 0 };
    const currentSeconds = localTimers[gameId] || 0;

    axios
      .put(`/api/admin/update-game-live/${gameId}`, {
        placarA: currentScore.placarA,
        placarB: currentScore.placarB,
        tempoAoVivo: formatTime(currentSeconds),
        isLive: false,
      })
      .then(() => {
        // somente ao pausar, sincroniza
        fetchGames();
      })
      .catch((err) => console.error('Erro ao pausar o jogo:', err));
  };

  return (
    <div className={styles['admin-games-container']}>
      <h1 className={styles['header']}>Gerenciamento de Jogos</h1>
      <div className={styles['buttons-container']}>
        <Button onClick={() => setModalCreateIsOpen(true)} className={styles['create-button']}>
          Criar Novo Jogo
        </Button>
        <Button
          onClick={async () => {
            try {
              await axios.post('/api/admin/notify-new-games', { games });
              alert('Notificações enviadas com sucesso!');
            } catch (error) {
              console.error('Erro ao enviar notificações:', error);
              alert('Erro ao enviar notificações.');
            }
          }}
          className={styles['create-button']}
        >
          Notificar Usuários
        </Button>
        <Button onClick={toggleShowGames} className={styles['toggle-button']}>
          {showTodayGames ? 'Exibir Jogos Anteriores' : 'Exibir Jogos de Hoje'}
        </Button>
      </div>

      <table className={styles['games-table']}>
        <thead>
          <tr>
            <th>Time A</th>
            <th>Time B</th>
            <th>Data do Jogo</th>
            <th>Status</th>
            <th>Placar / Tempo</th>
            <th>Controles Ao Vivo</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {(showTodayGames ? games : previousGames).map((game) => {
            const id = game._id;
            const localScore = localScores[id] || { placarA: 0, placarB: 0 };
            const localTime = localTimers[id] || 0;
            const timerRunning = !!timerIntervals.current[id];

            return (
              <tr key={id}>
                <td>
                  {game.teamA_badge && (
                    <img
                      src={game.teamA_badge}
                      alt={game.teamA_name}
                      width="20"
                      style={{ marginRight: '8px' }}
                    />
                  )}
                  {game.teamA_name}
                </td>
                <td>
                  {game.teamB_badge && (
                    <img
                      src={game.teamB_badge}
                      alt={game.teamB_name}
                      width="20"
                      style={{ marginRight: '8px' }}
                    />
                  )}
                  {game.teamB_name}
                </td>
                <td>{formatDate(game.matchDate)}</td>
                <td>
                  <select
                    onChange={(e) => handleStatusChange(id, e.target.value)}
                    value={game.status}
                    className={styles['status-select']}
                  >
                    <option value="Pendente">Pendente</option>
                    <option value="Green">Green</option>
                    <option value="Red">Red</option>
                  </select>
                </td>
                <td>
                  <div>Placar: {localScore.placarA} - {localScore.placarB}</div>
                  <div>
                    Tempo:{' '}
                    {timerRunning
                      ? formatTime(localTime)
                      : game.isLive
                      ? formatTime(localTime)
                      : (game.tempoAoVivo || '00:00')}
                  </div>
                </td>
                <td>
                  <div style={{ marginBottom: '0.5rem' }}>
                    <button
                      className={styles['live-control-button']}
                      onClick={() => updateScore(id, 'A', 1)}
                    >
                      +1 A
                    </button>
                    <button
                      className={styles['live-control-button']}
                      onClick={() => updateScore(id, 'A', -1)}
                    >
                      -1 A
                    </button>
                  </div>
                  <div style={{ marginBottom: '0.5rem' }}>
                    <button
                      className={styles['live-control-button']}
                      onClick={() => updateScore(id, 'B', 1)}
                    >
                      +1 B
                    </button>
                    <button
                      className={styles['live-control-button']}
                      onClick={() => updateScore(id, 'B', -1)}
                    >
                      -1 B
                    </button>
                  </div>
                  <div>
                    {timerRunning ? (
                      <button
                        className={styles['delete-button']}
                        onClick={() => stopTimer(id)}
                      >
                        Pausar Tempo
                      </button>
                    ) : (
                      <button
                        className={styles['edit-button']}
                        onClick={() => startTimer(id)}
                      >
                        Iniciar Tempo
                      </button>
                    )}
                  </div>
                </td>
                <td>
                  <Button
                    onClick={() => openEditModal(game)}
                    className={styles['edit-button']}
                  >
                    Editar
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => openDeleteConfirm(id)}
                    className={styles['delete-button']}
                  >
                    Excluir
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className={styles['pagination']}>
        <button
          onClick={() => handlePageChange('prev')}
          disabled={currentPage === 1}
          className={styles['pagination-button']}
        >
          Anterior
        </button>
        <span className={styles['pagination-info']}>
          Página {currentPage} de {totalPages}
        </span>
        <button
          onClick={() => handlePageChange('next')}
          disabled={currentPage === totalPages}
          className={styles['pagination-button']}
        >
          Próximo
        </button>
      </div>
      
      {/* MODAL: Criar Jogo */}
      <Modal
        isOpen={modalCreateIsOpen}
        onRequestClose={() => setModalCreateIsOpen(false)}
        className={styles['modal']}
        overlayClassName={styles['modal-overlay']}
      >
        <h2 className={styles['modal-title']}>Criar Novo Jogo</h2>
        <form>
          <div className={styles['form-group']}>
            <label className={styles['modal-label']}>Time A</label>
            <Select
              options={teams}
              components={animatedComponents}
              onChange={(selectedOption) => handleTeamChange(selectedOption, 'teamA', newGame, setNewGame)}
              placeholder="Selecione o Time A"
              isSearchable
              getOptionLabel={(e) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={e.badge} alt={e.label} width="20" style={{ marginRight: '10px' }} />
                  {e.label}
                </div>
              )}
              styles={{
                control: (base) => ({
                  ...base,
                  backgroundColor: '#333',
                  borderColor: '#555',
                  color: '#e0e0e0',
                  borderRadius: '5px',
                  padding: '0.4rem',
                  boxShadow: 'none',
                  '&:hover': { borderColor: '#00e676' },
                }),
                placeholder: (base) => ({
                  ...base,
                  color: '#e0e0e0',
                }),
                singleValue: (base) => ({
                  ...base,
                  display: 'flex',
                  alignItems: 'center',
                  color: '#e0e0e0',
                }),
                menu: (base) => ({
                  ...base,
                  backgroundColor: '#333',
                  borderRadius: '5px',
                }),
                option: (base, state) => ({
                  ...base,
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: state.isFocused ? '#00e676' : '#333',
                  color: state.isFocused ? '#1a1a1a' : '#e0e0e0',
                  '&:active': { backgroundColor: '#00a844' },
                }),
              }}
            />
          </div>
          <div className={styles['form-group']}>
            <label className={styles['modal-label']}>Time B</label>
            <Select
              options={teams}
              components={animatedComponents}
              onChange={(selectedOption) => handleTeamChange(selectedOption, 'teamB', newGame, setNewGame)}
              placeholder="Selecione o Time B"
              isSearchable
              getOptionLabel={(e) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={e.badge} alt={e.label} width="20" style={{ marginRight: '10px' }} />
                  {e.label}
                </div>
              )}
              styles={{
                control: (base) => ({
                  ...base,
                  backgroundColor: '#333',
                  borderColor: '#555',
                  color: '#e0e0e0',
                  borderRadius: '5px',
                  padding: '0.4rem',
                  boxShadow: 'none',
                  '&:hover': { borderColor: '#00e676' },
                }),
                placeholder: (base) => ({
                  ...base,
                  color: '#e0e0e0',
                }),
                singleValue: (base) => ({
                  ...base,
                  display: 'flex',
                  alignItems: 'center',
                  color: '#e0e0e0',
                }),
                menu: (base) => ({
                  ...base,
                  backgroundColor: '#333',
                  borderRadius: '5px',
                }),
                option: (base, state) => ({
                  ...base,
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: state.isFocused ? '#00e676' : '#333',
                  color: state.isFocused ? '#1a1a1a' : '#e0e0e0',
                  '&:active': { backgroundColor: '#00a844' },
                }),
              }}
            />
          </div>
          <div className={styles['form-group']}>
            <label className={styles['modal-label']}>Data do Jogo</label>
            <input
              type="datetime-local"
              name="matchDate"
              onChange={(e) => handleInputChange(e, newGame, setNewGame)}
              className={styles['modal-input']}
            />
          </div>
          <div className={styles['form-group']}>
            <label className={styles['modal-label']}>Link do Jogo</label>
            <input
              type="text"
              name="matchLink"
              onChange={(e) => handleInputChange(e, newGame, setNewGame)}
              className={styles['modal-input']}
            />
          </div>
          <div className={styles['form-group']}>
            <label className={styles['modal-label']}>Probabilidade de Acerto (%)</label>
            <input
              type="number"
              name="probabilidade"
              value={newGame.probabilidade || ''}
              onChange={(e) => handleInputChange(e, newGame, setNewGame)}
              className={styles['modal-input']}
              min="0"
              max="100"
            />
          </div>
          <h5 className={styles['modal-subtitle']}>Apostas</h5>
          {newGame.bets.map((bet, index) => (
            <div key={index} className={styles['bet-group']}>
              <div className={styles['form-group']}>
                <label className={styles['modal-label']}>Título</label>
                <input
                  type="text"
                  value={bet.title}
                  onChange={(e) => handleBetChange(index, 'title', e.target.value, newGame, setNewGame)}
                  className={styles['modal-input']}
                />
              </div>
              <div className={styles['form-group']}>
                <label className={styles['modal-label']}>Subtítulo</label>
                <input
                  type="text"
                  value={bet.subtitle}
                  onChange={(e) => handleBetChange(index, 'subtitle', e.target.value, newGame, setNewGame)}
                  className={styles['modal-input']}
                />
              </div>
              <div className={styles['form-group']}>
                <label className={styles['modal-label']}>ODDS</label>
                <input
                  type="text"
                  value={bet.odds}
                  onChange={(e) => handleBetChange(index, 'odds', e.target.value, newGame, setNewGame)}
                  className={styles['modal-input']}
                />
              </div>
            </div>
          ))}
          <button
            type="button"
            onClick={() => handleAddBet(newGame, setNewGame)}
            className={styles['add-bet-button']}
          >
            Adicionar Aposta
          </button>
        </form>
        <div className={styles['modal-actions']}>
          <button
            onClick={() => setModalCreateIsOpen(false)}
            className={styles['modal-cancel-button']}
          >
            Cancelar
          </button>
          <button
            onClick={handleCreateGame}
            className={styles['modal-create-button']}
          >
            Criar Jogo
          </button>
        </div>
      </Modal>

      {/* MODAL: Confirmar Exclusão */}
      <Modal
        isOpen={!!deleteGameId}
        onRequestClose={() => setDeleteGameId(null)}
        className={styles['modal']}
        overlayClassName={styles['modal-overlay']}
      >
        <h2 className={styles['modal-title']}>Confirmar Exclusão</h2>
        <p className={styles['modal-text']}>Tem certeza de que deseja excluir este jogo?</p>
        <div className={styles['modal-actions']}>
          <button onClick={handleDeleteGame} className={styles['modal-cancel-button']}>
            Confirmar
          </button>
          <button
            onClick={() => setDeleteGameId(null)}
            className={styles['modal-create-button']}
          >
            Cancelar
          </button>
        </div>
      </Modal>

      {/* MODAL: Editar Jogo */}
      <Modal
        isOpen={modalEditIsOpen}
        onRequestClose={() => setModalEditIsOpen(false)}
        className={styles['modal']}
        overlayClassName={styles['modal-overlay']}
      >
        <h2 className={styles['modal-title']}>Editar Jogo</h2>
        {editGame && (
          <form>
            <div className={styles['form-group']}>
              <label className={styles['modal-label']}>Time A</label>
              <Select
                options={teams}
                onChange={(selectedOption) => handleTeamChange(selectedOption, 'teamA', editGame, setEditGame)}
                placeholder="Selecione o Time A"
                styles={{
                  control: (base) => ({
                    ...base,
                    backgroundColor: '#333',
                    borderColor: '#555',
                    color: '#e0e0e0',
                    borderRadius: '5px',
                    padding: '0.4rem',
                    boxShadow: 'none',
                    '&:hover': { borderColor: '#00e676' },
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: '#e0e0e0',
                  }),
                  singleValue: (base) => ({
                    ...base,
                    color: '#e0e0e0',
                  }),
                  menu: (base) => ({
                    ...base,
                    backgroundColor: '#333',
                    borderRadius: '5px',
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isFocused ? '#00e676' : '#333',
                    color: state.isFocused ? '#1a1a1a' : '#e0e0e0',
                    '&:active': { backgroundColor: '#00a844' },
                  }),
                }}
              />
            </div>
            <div className={styles['form-group']}>
              <label className={styles['modal-label']}>Time B</label>
              <Select
                options={teams}
                onChange={(selectedOption) => handleTeamChange(selectedOption, 'teamB', editGame, setEditGame)}
                placeholder="Selecione o Time B"
                styles={{
                  control: (base) => ({
                    ...base,
                    backgroundColor: '#333',
                    borderColor: '#555',
                    color: '#e0e0e0',
                    borderRadius: '5px',
                    padding: '0.4rem',
                    boxShadow: 'none',
                    '&:hover': { borderColor: '#00e676' },
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: '#e0e0e0',
                  }),
                  singleValue: (base) => ({
                    ...base,
                    color: '#e0e0e0',
                  }),
                  menu: (base) => ({
                    ...base,
                    backgroundColor: '#333',
                    borderRadius: '5px',
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isFocused ? '#00e676' : '#333',
                    color: state.isFocused ? '#1a1a1a' : '#e0e0e0',
                    '&:active': { backgroundColor: '#00a844' },
                  }),
                }}
              />
            </div>
            <div className={styles['form-group']}>
              <label className={styles['modal-label']}>Data do Jogo</label>
              <input
                type="datetime-local"
                name="matchDate"
                value={editGame.matchDate ? editGame.matchDate.slice(0, 16) : ''}
                onChange={(e) => handleInputChange(e, editGame, setEditGame)}
                className={styles['modal-input']}
              />
            </div>
            <div className={styles['form-group']}>
              <label className={styles['modal-label']}>Link do Jogo</label>
              <input
                name="matchLink"
                value={editGame.matchLink || ''}
                onChange={(e) => handleInputChange(e, editGame, setEditGame)}
                className={styles['modal-input']}
              />
            </div>
            <div className={styles['form-group']}>
              <label className={styles['modal-label']}>Probabilidade de Acerto (%)</label>
              <input
                type="number"
                name="probabilidade"
                value={editGame.probabilidade || ''}
                onChange={(e) => handleInputChange(e, editGame, setEditGame)}
                className={styles['modal-input']}
                min="0"
                max="100"
              />
            </div>
            <h5 className={styles['modal-subtitle']}>Apostas</h5>
            {editGame.bets.map((bet, index) => (
              <div key={index} className="mb-3">
                <div className={styles['form-group']}>
                  <label className={styles['modal-label']}>Título</label>
                  <input
                    type="text"
                    value={bet.title}
                    onChange={(e) => handleBetChange(index, 'title', e.target.value, editGame, setEditGame)}
                    className={styles['modal-input']}
                  />
                </div>
                <div className={styles['form-group']}>
                  <label className={styles['modal-label']}>Subtítulo</label>
                  <input
                    type="text"
                    value={bet.subtitle}
                    onChange={(e) => handleBetChange(index, 'subtitle', e.target.value, editGame, setEditGame)}
                    className={styles['modal-input']}
                  />
                </div>
                <div className={styles['form-group']}>
                  <label className={styles['modal-label']}>ODDS</label>
                  <input
                    type="text"
                    value={bet.odds}
                    onChange={(e) => handleBetChange(index, 'odds', e.target.value, editGame, setEditGame)}
                    className={styles['modal-input']}
                  />
                </div>
              </div>
            ))}
            <button
              type="button"
              onClick={() => handleAddBet(editGame, setEditGame)}
              className={styles['add-bet-button']}
            >
              Adicionar Aposta
            </button>
          </form>
        )}
        <div className={styles['modal-actions']}>
          <button
            onClick={() => setModalEditIsOpen(false)}
            className={styles['modal-cancel-button']}
          >
            Cancelar
          </button>
          <button
            onClick={handleEditGame}
            className={styles['modal-create-button']}
          >
            Salvar Alterações
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default AdminGames;
