import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from './Navbar';
import Modal from 'react-modal';
import Footer from './Footer';
import styles from '../styles/pages/UserDashboard.module.css';
import campoImg from '../assets/campo.png';

const UserDashboard = () => {
  const [games, setGames] = useState([]);
  const [selectedGame, setSelectedGame] = useState(null);
  const [betIndex, setBetIndex] = useState(0);

  // Carrega jogos do backend
  const fetchGames = async () => {
    try {
      const response = await axios.get('/api/users/today-games', {
        headers: { 'Cache-Control': 'no-store' },
      });
      setGames(response.data.games);
    } catch (error) {
      console.error('Erro ao carregar jogos:', error);
    }
  };

  // Polling a cada 1s
  useEffect(() => {
    fetchGames();
    const intervalId = setInterval(fetchGames, 1000);
    return () => clearInterval(intervalId);
  }, []);

  // SINCRONIZA O MODAL: sempre que 'games' mudar (polling) e se tivermos 'selectedGame' aberto,
  // atualiza 'selectedGame' com os dados mais recentes desse jogo (mesmo _id) em 'games'.
  useEffect(() => {
    if (selectedGame) {
      const updated = games.find((g) => g._id === selectedGame._id);
      if (updated) {
        setSelectedGame(updated);
      }
    }
  }, [games, selectedGame]);

  // Formata data "YYYY-MM-DDTHH:mm:ss" -> "HH:mm DD/MM/YYYY"
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const [date, time] = dateString.split('T');
    const [year, month, day] = date.split('-');
    return `${time.slice(0, 5)} ${day}/${month}/${year}`;
  };

  // Abre modal
  const openModal = (game) => {
    setSelectedGame(game);
    setBetIndex(0);
  };

  // Fecha modal
  const closeModal = () => {
    setSelectedGame(null);
    setBetIndex(0);
  };

  // Próxima aposta
  const nextBet = () => {
    if (selectedGame && betIndex < selectedGame.bets.length - 1) {
      setBetIndex(betIndex + 1);
    }
  };

  // Aposta anterior
  const prevBet = () => {
    if (betIndex > 0) {
      setBetIndex(betIndex - 1);
    }
  };

  return (
    <div className={styles['user-dashboard']}>
      <Navbar />

      <div className={styles['games-table-container']}>
        <h2>Jogos de Hoje</h2>

        <table className={styles['games-table']}>
          <thead>
            <tr>
              <th>CONFRONTO</th>
              <th>ODD</th>
              <th>DATA E HORA</th>
            </tr>
          </thead>
          <tbody>
            {games.map((game) => (
              <tr key={game._id} onClick={() => openModal(game)}>
                <td data-label="Confronto">
                  <div className={styles.confronto}>
                    {/* Time A */}
                    <div className={styles.teamBlock}>
                      <img
                        src={game.teamA_badge}
                        alt={game.teamA_name}
                        className={styles.teamBadge}
                      />
                      <span className={styles.teamName}>{game.teamA_name}</span>
                    </div>

                    {/* Placar/Tempo */}
                    <div className={styles.scoreBlock}>
                      <span className={styles.score}>
                        {game.placarA} - {game.placarB}
                      </span>
                      <span
                        className={
                          game.isLive ? styles.time : styles.timePaused
                        }
                      >
                        {game.isLive
                          ? game.tempoAoVivo
                          : formatDate(game.matchDate)}
                      </span>
                    </div>

                    {/* Time B */}
                    <div className={styles.teamBlock}>
                      <img
                        src={game.teamB_badge}
                        alt={game.teamB_name}
                        className={styles.teamBadge}
                      />
                      <span className={styles.teamName}>{game.teamB_name}</span>
                    </div>
                  </div>
                </td>
                <td data-label="ODD">{game.bets[0]?.odds || 'N/A'}</td>
                <td data-label="Data e Hora">{formatDate(game.matchDate)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* MODAL de detalhes */}
      <Modal
        isOpen={!!selectedGame}
        onRequestClose={closeModal}
        className={styles['game-modal']}
        overlayClassName={styles['modal-overlay']}
      >
        {selectedGame && (
          <>
            {/* Imagem do campo */}
            <div className={styles.fieldImageContainer}>
              <img src={campoImg} alt="Campo" className={styles.fieldImage} />
            </div>

            {/* Conteúdo do Modal */}
            <div className={styles['modal-content']}>
              <div className={styles.modalConfronto}>
                {/* Time A */}
                <div className={styles.modalTeamBlock}>
                  <img
                    src={selectedGame.teamA_badge}
                    alt={selectedGame.teamA_name}
                    className={styles.teamBadge}
                  />
                  <span className={styles.modalTeamName}>
                    {selectedGame.teamA_name}
                  </span>
                </div>

                {/* Placar e Tempo */}
                <div className={styles.modalScoreBlock}>
                  <span className={styles.modalScore}>
                    {selectedGame.placarA} - {selectedGame.placarB}
                  </span>
                  <span className={styles.modalTime}>
                    {selectedGame.isLive
                      ? selectedGame.tempoAoVivo
                      : formatDate(selectedGame.matchDate)}
                  </span>
                </div>

                {/* Time B */}
                <div className={styles.modalTeamBlock}>
                  <img
                    src={selectedGame.teamB_badge}
                    alt={selectedGame.teamB_name}
                    className={styles.teamBadge}
                  />
                  <span className={styles.modalTeamName}>
                    {selectedGame.teamB_name}
                  </span>
                </div>
              </div>

              {/* Título da aposta, ODD, subtítulo */}
              <div className={styles.modalTitleContainer}>
                <h3>{selectedGame.bets[betIndex]?.title}</h3>
                <div className={styles.oddContainer}>
                  <span className={styles.oddLabel}>ODD:</span>
                  <span className={styles.odd}>
                    {selectedGame.bets[betIndex]?.odds}
                  </span>
                </div>
              </div>
              <p className={styles.subtitle}>
                {selectedGame.bets[betIndex]?.subtitle}
              </p>

              {/* Barra de probabilidade */}
              <div className={styles.probabilidadeContainer}>
                <label className={styles.probabilidadeLabel}>
                  Probabilidade de Acerto:
                </label>
                <div className={styles.progressBar}>
                  <div
                    className={styles.progress}
                    style={{ width: `${selectedGame.probabilidade || 0}%` }}
                  >
                    {selectedGame.probabilidade || 0}%
                  </div>
                </div>
              </div>

              {/* Link do jogo */}
              {selectedGame.matchLink && (
                <a
                  href={selectedGame.matchLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className={styles.gameLinkBtn}>Link do Jogo</button>
                </a>
              )}

              {/* Navegação de apostas */}
              <div className={styles.betNavigation}>
                {selectedGame.bets.length > 1 && (
                  <>
                    <button onClick={prevBet} disabled={betIndex === 0}>
                      ◄
                    </button>
                    <button
                      onClick={nextBet}
                      disabled={betIndex === selectedGame.bets.length - 1}
                    >
                      ►
                    </button>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </Modal>

      <Footer />
    </div>
  );
};

export default UserDashboard;
